import React, { Component } from "react";

class EvalGauge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            evalHeight: '50%',
            currentScore: 0
        };
    }

    componentWillReceiveProps(nextProps) {
        var currentEval = nextProps.evalScore;
        if (currentEval === "...")
            return;

        if (this.state.currentScore !== currentEval) {
            var pct = "50%";
            if (currentEval >= -0.3 && currentEval <= 0.3)
                pct = "50%";
            else if (currentEval < 0 && currentEval > -1)
                pct = "55%";
            else if (currentEval > 0 && currentEval < 1)
                pct = "45%";
            else if (currentEval > 0)
                pct = "25%";
            else if (currentEval < 0)
                pct = "75%";
            else
                pct = "0%";

            this.setState({
                evalHeight: pct,
                currentScore: currentEval
            });
        }
    }

    render() {
        return <div className="eval-gauge">
            <div className="black" style={{ height: this.state.evalHeight }}></div>
            <tick style={{ height: '12.5%' }} />
            <tick style={{ height: '25%' }} />
            <tick style={{ height: '37.5%' }} />
            <tick className="zero" style={{ height: '50%' }} />
            <tick style={{ height: '62.5%' }} />
            <tick style={{ height: '75%' }} />
            <tick style={{ height: '87.5%' }} />
            <tick style={{ height: '100%' }} />
        </div>;
    }
}

export default EvalGauge;
