import React, { Component } from 'react';
import Chessboard from 'chessboardjsx';
import Chess from "chess.js";
import { Button, Card, CardBody, Container, Table, Input } from 'reactstrap';

export class Puzzles extends Component {
    static displayName = Puzzles.name;
    state = {
        pressedKeys: [],
        rpuzFen: '',
        rpuzCanMove: false,
        rpuzInfo: {},
        rpuzYourMove: '',
        rpuzTurn: '',
        rpuzEngineMoveDisplay: '',
        pgn: '',
        gameObj: {},
        boardOrientation: 'white',
        puzzleFen: '',
        searchLastName: '',
        tacticFen: '',
        origTacticFen: '',
        tacticBoardOrientation: 'white',
        tacticTurn: '',
        tacticPrevMove: '',
        currPuzzleObj: null,
        puzzleMovesIn: 0,
        tacticCanMove: false,
        ChessboardBorder: {
            border: "10px solid black"
        },
        searchOpeningMoves: '',
        tacticOpening: ''
    };

    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.getRpuz = this.getRpuz.bind(this);
        this.rpuzOnDrop = this.rpuzOnDrop.bind(this);
        this.flipBoard = this.flipBoard.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.openAnalysisBoard = this.openAnalysisBoard.bind(this);
        this.tacticFlipBoard = this.tacticFlipBoard.bind(this);
        this.tacticOpenAnalysisBoard = this.tacticOpenAnalysisBoard.bind(this);
        this.getTactic = this.getTactic.bind(this);
        this.loadTactic = this.loadTactic.bind(this);
        this.makeNextMove = this.makeNextMove.bind(this);
        this.resetChessboardBorder = this.resetChessboardBorder.bind(this);
        this.showSolution = this.showSolution.bind(this);
        this.onChangeSearchOpeningMoves = this.onChangeSearchOpeningMoves.bind(this);
    };

    componentDidMount() {
        this.rpuzGame = new Chess();
        this.tacticGame = new Chess();
        this.setState({
            defaultStyles: this.state.squareStyles,
        });
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    onChangeLastName = e => {
        this.setState({ searchLastName: e.target.value });
    }

    onChangeSearchOpeningMoves = e => {
        this.setState({ searchOpeningMoves: e.target.value });
    }

    onChangeTacticOpening = e => {
        this.setState({ tacticOpening: e.target.value });
    }

    onKeyDown = function (e) {
        let pressedKeysD = this.state.pressedKeys;
        pressedKeysD[e.keyCode] = true;
        this.setState({ pressedKeys: pressedKeysD });
    }

    onKeyUp = function (e) {
        let pressedKeysD = this.state.pressedKeys;
        pressedKeysD[e.keyCode] = false;
        this.setState({ pressedKeys: pressedKeysD });
    }

    flipBoard = function () {
        this.setState({ boardOrientation: this.state.boardOrientation === 'black' ? 'white' : 'black' });
    }

    tacticFlipBoard = function () {
        this.setState({ tacticBoardOrientation: this.state.tacticBoardOrientation === 'black' ? 'white' : 'black' });
    }

    openAnalysisBoard = function () {
        var url = "https://lichess.org/analysis/" + this.state.puzzleFen;
        window.open(url);
    }

    tacticOpenAnalysisBoard = function () {
        var url = "https://lichess.org/analysis/" + this.state.origTacticFen;
        window.open(url);
    }

    tacticOnDrop = ({ sourceSquare, targetSquare }) => {
        if (!this.state.tacticCanMove) { return;}
        if (this.state.currPuzzleObj.moves[this.state.puzzleMovesIn] === sourceSquare + targetSquare) {
            let move = this.tacticGame.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: "q"
            });
            this.tacticGame.move(move);
            this.setState({ tacticFen: this.tacticGame.fen() });
            if (this.state.currPuzzleObj.moves.length - 1 === this.state.puzzleMovesIn) {
                this.setState({ tacticCanMove: false });
                this.setState({
                    ChessboardBorder: {
                        border: "10px solid green"
                    }
                });
            } else {
                this.setState({ puzzleMovesIn: this.state.puzzleMovesIn + 2 });
                setTimeout(this.makeNextMove, 500);
            }
        } else {
            this.setState({
                ChessboardBorder: {
                    border: "10px solid red"
                }
            });
            setTimeout(this.resetChessboardBorder, 500);
        }
    }

    resetChessboardBorder = function () {
        this.setState({
            ChessboardBorder: {
                border: "10px solid black"
            }
        });
    }

    showSolution = function () {
        if (!this.state.tacticCanMove) { return; }
        let move = {
            from: this.state.currPuzzleObj.moves[this.state.puzzleMovesIn].substring(0, 2),
            to: this.state.currPuzzleObj.moves[this.state.puzzleMovesIn].substring(2, 4),
            promotion: this.state.currPuzzleObj.moves[this.state.puzzleMovesIn].length === 5 ? this.state.currPuzzleObj.moves[this.state.puzzleMovesIn].substring(4, 5) : 'q'
        };
        this.tacticGame.move(move);
        this.setState({ tacticFen: this.tacticGame.fen() });
        if (this.state.currPuzzleObj.moves.length - 1 === this.state.puzzleMovesIn) {
            this.setState({ tacticCanMove: false });
            this.setState({
                ChessboardBorder: {
                    border: "10px solid green"
                }
            });
        } else {
            this.setState({ puzzleMovesIn: this.state.puzzleMovesIn + 2 });
            setTimeout(this.makeNextMove, 1200);
        }
        
    }

    makeNextMove() {
        this.tacticGame.move(this.state.currPuzzleObj.moves[this.state.puzzleMovesIn - 1], { sloppy: true });
        this.setState({ tacticFen: this.tacticGame.fen() });
    }

    getTactic(event) {
        event.preventDefault();
        if (this.state.fetchInProgress) { return; }
        this.setState({
            currPuzzleObj: {}
        });
        fetch('api/GetTactic?Opening=' + this.state.tacticOpening)
            .then(response => response.json())
            .then(data => this.setState({
                currPuzzleObj: data
            }, this.loadTactic))

    }

    loadTactic = function () {
        this.setState({
            tacticFen: this.state.currPuzzleObj.fen,
            origTacticFen: this.state.currPuzzleObj.fen,
            puzzleMovesIn: 0,
            tacticCanMove: true,
            tacticPrevMove: "",
            ChessboardBorder: {
                border: "10px solid black"
            }
        });
        let currPuzzleObjTest = this.state.currPuzzleObj;
        if (currPuzzleObjTest.moves.length === 10) {
            currPuzzleObjTest.moves.pop();
            this.setState({ currPuzzleObj: currPuzzleObjTest });
        }
        this.tacticGame.load(this.state.currPuzzleObj.fen);
        this.setState({
            tacticTurn: this.tacticGame.turn(),
            tacticPrevMove: this.state.currPuzzleObj.previousMove
        });
        this.setState({ tacticBoardOrientation: this.tacticGame.turn() === 'w' ? 'white' : 'black' });
    }

    getRpuz(event) {
        event.preventDefault();
        if (this.state.fetchInProgress) { return; }
        this.setState({
            gameObj: {},
            rpuzYourMove: '',
            rpuzEngineMoveDisplay: ''
        })
        fetch('api/GetRandomPgn?PlayerName=' + this.state.searchLastName + '&Opening=' + this.state.searchOpeningMoves)
            .then(response => response.json())
            .then(data => this.setState({
                gameObj: data,
                pgn: data.pgnMoves,
                fetchInProgress: false
            }, this.loadRpuz))
    }

    loadRpuz = function () {
        this.rpuzGame.load_pgn(this.state.pgn);
        let currHistory = this.rpuzGame.history();
        var i = 1;
        if (currHistory.length > 80) { i = Math.round(Math.random() * 60) + 18; }
        else if (currHistory.length > 50) { i = Math.round(Math.random() * 30) + 18; }
        else if (currHistory.length > 20) { i = currHistory.length - (Math.round((Math.random() * 10) + 1)); }
        else if (currHistory.length > 2) { i = currHistory.length - 2; }
        this.rpuzGame.reset()
        for (var j = 0; j < i; j++) {
            this.rpuzGame.move(currHistory[j]);
        }

        let moveDesc = "(move " + Math.floor(i / 2) + ")";
        this.setState({
            rpuzFen: this.rpuzGame.fen(),
            rpuzCanMove: true,
            rpuzTurn: this.rpuzGame.turn() === 'w' ? "White " + moveDesc : "Black " + moveDesc,
            boardOrientation: this.rpuzGame.turn() === 'w' ? "White" : "Black",
            puzzleFen: this.rpuzGame.fen(),
            rpuzInfo: {
                engineMove: '',
                engineEvalDisplay: '',
                yourEvalDisplay: ''
            },
            rpuzEngineMoveDisplay: ''
        });
    }

    rpuzOnDrop = ({ sourceSquare, targetSquare }) => {
        if (this.state.fetchInProgress) { return; }
        if (!this.state.rpuzCanMove) { return; }
        let fen1 = this.rpuzGame.fen();
        let move = this.rpuzGame.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q"
        });
        if (move === null) { return; }
        this.rpuzGame.move(move);
        this.setState({
            rpuzYourMove: move.san,
            rpuzFen: this.rpuzGame.fen()
        });
        let fen2 = this.rpuzGame.fen();
        this.getRpuzAnalysis(fen1, fen2);
    }

    async getRpuzAnalysis(fen1, fen2) {
        this.setState({ fetchInProgress: true });
        this.setState({
            rpuzInfo: {
                engineMove: "Calculating...",
                engineEvalDisplay: "Calculating...",
                yourEvalDisplay: "Calculating..."
            },
            rpuzEngineMoveDisplay: "Calculating..."
        })

        const url = 'api/GetRpuzAnalysis?fen1=' + fen1 + '&fen2=' + fen2 + '&turn=' + this.rpuzGame.turn() + '&depth=26';
        //console.log(url);
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            rpuzInfo: data,
            fetchInProgress: false
        });
        var rpuzTestGame = new Chess(fen1);
        let rpuzTestMove = rpuzTestGame.move({
            from: data.engineMove.substring(0, 2),
            to: data.engineMove.substring(2, 4)
        });
        this.setState({ rpuzEngineMoveDisplay: rpuzTestMove.san });
    }

    goMaxDepth(event) {
        event.preventDefault();

        this.setState({ maxAnalysisInProgress: true });
        this.getAnalysisFromFen(this.state.fen);
    }

    render() {

        return (
            <div>
                <Button className="btn btn-link collapse-btn" id="toggler3">Random Puzzles</Button>
                <Card>
                    <CardBody className="chessboard-center">
                        <Container>
                            <table>
                                <tr>
                                    <td width="560px">
                                        <Chessboard position={this.state.rpuzFen} draggable={true} onDrop={this.rpuzOnDrop} orientation={this.state.boardOrientation} />
                                    </td>
                                    <td width="10px" />
                                    <td className="td-align-top">
                                        <Input placeholder="Last Name" value={this.state.searchLastName} onChange={this.onChangeLastName} style={{ marginBottom: '5px' }} />
                                        <Input placeholder="Openings Moves, e.g. 1.Nf3 d5" value={this.state.searchOpeningMoves} onChange={this.onChangeSearchOpeningMoves} style={{ marginBottom: '5px' }} />
                                        <Button color="primary" onClick={this.getRpuz}>Generate Random Puzzle</Button>
                                        <div style={{ padding: '10px' }} />
                                        <Table striped style={{ border: '3px solid black' }}>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">Your Choice:</td>
                                                    <td>{this.state.rpuzYourMove}</td>
                                                    <td>{this.state.rpuzInfo.yourEvalDisplay}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Engine's Choice:</td>
                                                    <td>{this.state.rpuzEngineMoveDisplay}</td>
                                                    <td>{this.state.rpuzInfo.engineEvalDisplay}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Table striped>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">Current Turn:</td>
                                                    {this.state.rpuzTurn &&
                                                        <td colSpan="2">{this.state.rpuzTurn}</td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Players:</td>
                                                    {this.state.gameObj.whitePlayer &&
                                                        <td>
                                                            {this.state.gameObj.whitePlayer} ({this.state.gameObj.whiteElo})
                                                        &nbsp;vs.&nbsp;
                                                        {this.state.gameObj.blackPlayer} ({this.state.gameObj.blackElo})
                                                    </td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Event:</td>
                                                    {this.state.gameObj.eventName &&
                                                        <td colSpan="2">{this.state.gameObj.eventName} ({this.state.gameObj.eventYear})</td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Result:</td>
                                                    {this.state.gameObj.result &&
                                                        <td colSpan="2">{this.state.gameObj.result}</td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Button className="form-padding" color="warning" onClick={this.flipBoard}>Flip Board</Button>
                                        <br />
                                        <Button className="form-padding" color="info" onClick={this.openAnalysisBoard}>Open Analysis Board</Button>

                                    </td>
                                </tr>
                            </table>
                        </Container>
                    </CardBody>
                </Card>

                <Button className="btn btn-link collapse-btn" id="toggler4">Tactics</Button>
                <Card>
                    <CardBody className="chessboard-center">
                        <Container>
                            <table>
                                <tr>
                                    <td width="560px" style={this.state.ChessboardBorder}>
                                        <Chessboard position={this.state.tacticFen} draggable={true} onDrop={this.tacticOnDrop} orientation={this.state.tacticBoardOrientation} />
                                    </td>
                                    <td width="10px" />
                                    <td className="td-align-top">
                                        <Input placeholder="Openings Moves, e.g. 1.Nf3 d5" value={this.state.tacticOpening} onChange={this.onChangeTacticOpening} style={{ marginBottom: '5px' }} />
                                        <Button color="primary" onClick={this.getTactic}>Find Tactical Puzzle</Button>
                                        <div style={{ padding: '10px' }} />
                                        <Table striped>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">Current Turn:</td>
                                                    {this.state.tacticTurn &&
                                                        <td colSpan="2">{this.state.tacticTurn === 'w' ? 'white' : 'black'}</td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Previous Move:</td>
                                                    {this.state.tacticPrevMove &&
                                                        <td>
                                                        {this.state.tacticPrevMove}
                                                    </td>
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Game:</td>
                                                    {
                                                        this.state.currPuzzleObj === null ? "" : this.state.currPuzzleObj.whitePlayer + " - " + this.state.currPuzzleObj.blackPlayer + ", " + this.state.currPuzzleObj.eventName + " " + this.state.currPuzzleObj.eventYear
                                                    } : <td colSpan="2"></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Button className="form-padding" color="warning" onClick={this.tacticFlipBoard}>Flip Board</Button>
                                        <br />
                                        <Button className="form-padding" color="info" onClick={this.tacticOpenAnalysisBoard}>Open Analysis Board</Button>
                                        <br />
                                        <Button className="form-padding" color="primary" onClick={this.showSolution}>Show Solution</Button>

                                    </td>
                                </tr>
                            </table>
                        </Container>
                    </CardBody>
                </Card>

            </div >
        );
    }
}

