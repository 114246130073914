import React, { Component } from 'react';
import Chessboard from 'chessboardjsx';
import Chess from "chess.js";
import { Button, Card, CardBody, Container, Table } from 'reactstrap';

export class LoadPuzzle extends Component {
    static displayName = LoadPuzzle.name;
    state = {
        pressedKeys: [],
        currFen: '',
        rpuzCanMove: true,
        rpuzInfo: {},
        rpuzYourMove: '',
        rpuzTurn: '',
        rpuzEngineMoveDisplay: '',
        pgn: '',
        gameObj: {},
        boardOrientation: 'white',
        puzzleFen: '',
    };

    constructor(props) {
        super(props);
        this.flipBoard = this.flipBoard.bind(this);
        this.openAnalysisBoard = this.openAnalysisBoard.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.getRpuzAnalysis = this.getRpuzAnalysis.bind(this);
    };

    componentDidMount() {
        this.rpuzGame = new Chess();
        let param = window.location.pathname;
        let testFen = param.replace(/%20/g, ' ');
        this.rpuzGame.load(testFen.substring(12));
        this.setState({
            rpuzTurn: this.rpuzGame.turn(),
            currFen: testFen.substring(12),
            puzzleFen: testFen.substring(12)
        });
        if (this.rpuzGame.turn() === 'b') {
            this.flipBoard();
        }
    }


    flipBoard = function () {
        this.setState({ boardOrientation: this.state.boardOrientation === 'black' ? 'white' : 'black' });
    }

    openAnalysisBoard = function () {
        var url = "https://lichess.org/analysis/" + this.state.puzzleFen;
        window.open(url);
    }

    onDrop = ({ sourceSquare, targetSquare }) => {
        this.rpuzGame.load(this.state.currFen);
        if (this.state.fetchInProgress) { return; }
        if (!this.state.rpuzCanMove) { return; }
        let fen1 = this.rpuzGame.fen();
        let move = this.rpuzGame.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q"
        });
        if (move === null) { return; }
        this.setState({ rpuzCanMove: false });
        this.rpuzGame.move(move);
        this.setState({
            rpuzYourMove: move.san,
            rpuzFen: this.rpuzGame.fen()
        });
        let fen2 = this.rpuzGame.fen();
        this.getRpuzAnalysis(fen1, fen2);
    }

    async getRpuzAnalysis(fen1, fen2) {
        this.setState({ fetchInProgress: true });
        this.setState({
            rpuzInfo: {
                engineMove: "Calculating...",
                engineEvalDisplay: "Calculating...",
                yourEvalDisplay: "Calculating..."
            },
            rpuzEngineMoveDisplay: "Calculating..."
        })

        const url = 'api/GetRpuzAnalysis?fen1=' + fen1 + '&fen2=' + fen2 + '&turn=' + this.rpuzGame.turn() + '&depth=25';
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            rpuzInfo: data,
            fetchInProgress: false
        });
        var rpuzTestGame = new Chess(fen1);
        let rpuzTestMove = rpuzTestGame.move({
            from: data.engineMove.substring(0, 2),
            to: data.engineMove.substring(2, 4)
        });
        this.setState({ rpuzEngineMoveDisplay: rpuzTestMove.san });
        this.setState({ rpuzCanMove: true });
    }



    render() {

        return (
            <div>
                <Button className="btn btn-link collapse-btn" id="toggler3">Find a New Move in This Position</Button>
                <Card>
                    <CardBody className="chessboard-center">
                        <Container>
                            <table>
                                <tr>
                                    <td>
                                        <Chessboard position={this.state.currFen} draggable={true} onDrop={this.onDrop} orientation={this.state.boardOrientation} />
                                    </td>
                                    <td>
                                        <div style={{ width: '30px' }} />
                                    </td>
                                    <td>
                                        <div style={{ padding: '10px' }} />
                                        <Table striped style={{ border: '3px solid black' }}>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">Your Choice:</td>
                                                    <td>{this.state.rpuzYourMove}</td>
                                                    <td>{this.state.rpuzInfo.yourEvalDisplay}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Engine's Choice:</td>
                                                    <td>{this.state.rpuzEngineMoveDisplay}</td>
                                                    <td>{this.state.rpuzInfo.engineEvalDisplay}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Table striped>
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">Current Turn:</td>
                                                    {this.state.rpuzTurn &&
                                                        <td colSpan="2">{this.state.rpuzTurn}</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Button className="form-padding" color="warning" onClick={this.flipBoard}>Flip Board</Button>
                                        <br />
                                        <Button className="form-padding" color="info" onClick={this.openAnalysisBoard}>Open Analysis Board</Button>

                                    </td>
                                </tr>
                            </table>
                        </Container>
                    </CardBody>
                </Card>

            </div >
        );
    }
}

