import React, { Component } from 'react';
import Chessboard from 'chessboardjsx';
import Chess from "chess.js";
import { Button, Card, CardBody, Container, Label, Input } from 'reactstrap';
import EvalGauge from '../components/EvalGauge';

export class Viewer extends Component {
    static displayName = Viewer.name;
    state = {
        boardOrientation: 'white',
        fen: '',
        currPgn: '',
        gamePlayInProgress: false,
        transitionTime: 3,
        currTimeouts: [],
        pgnObj: [],
        evalScore: 0
    };

    constructor(props) {
        super(props);
        this.playGame = this.playGame.bind(this);
        this.handleImportPgn = this.handleImportPgn.bind(this);
        this.makeMove = this.makeMove.bind(this);
        this.endGame = this.endGame.bind(this);
        this.flipBoard = this.flipBoard.bind(this);
        this.handleImportFile = this.handleImportFile.bind(this);
        this.finishImportFile = this.finishImportFile.bind(this);
        this.handleTalRequest = this.handleTalRequest.bind(this);
        this.handleCapablancaRequest = this.handleCapablancaRequest.bind(this);
        this.handleFischerRequest = this.handleFischerRequest.bind(this);
        this.handleNezhmetdinovRequest = this.handleNezhmetdinovRequest.bind(this);
        this.handleCarlsenRequest = this.handleCarlsenRequest.bind(this);
        this.handleGiriRequest = this.handleGiriRequest.bind(this);
        this.fetchGameFromFolder = this.fetchGameFromFolder.bind(this);
        this.handleNimzoRequest = this.handleNimzoRequest.bind(this);
    };

    componentDidMount() {
        this.game = new Chess();
    }

    playGame = function (pgn) {
        if (this.state.gamePlayInProgress) {
            return;
        }
        this.setState({ gamePlayInProgress: true });
        this.game.load_pgn(pgn);
        let currHistory = this.game.history();
        this.game.reset();
        this.setState({ fen: this.game.fen() });
        for (var i = 0; i < currHistory.length; i++) {
            let currTimeoutsD = this.state.currTimeouts;
            currTimeoutsD.push(setTimeout(this.makeMove, this.state.transitionTime * 1000 * (i + 1), currHistory[i], currHistory.length - i));
            this.setState({ currTimeouts: currTimeoutsD });
        }
    }

    makeMove = function (move, number) {
        this.game.move(move);
        this.setState({ fen: this.game.fen() });
        if (number === 1) {
            this.setState({ gamePlayInProgress: false })
        }
        this.calculateEval();
    }

    async calculateEval() {
        var url;
        if (this.state.transitionTime < 0.5) {
            url = 'api/GetEvalFromFen?id=' + this.game.fen() + '&depth=13&turn=' + this.game.turn();
        } else if (this.state.transitionTime < 3) {
            url = 'api/GetEvalFromFen?id=' + this.game.fen() + '&depth=17&turn=' + this.game.turn();
        } else if (this.state.transitionTime < 5) {
            url = 'api/GetEvalFromFen?id=' + this.game.fen() + '&depth=20&turn=' + this.game.turn();
        } else {
            url = 'api/GetEvalFromFen?id=' + this.game.fen() + '&depth=22&turn=' + this.game.turn();
        }
        var response = await fetch(url);
        var currentEval = await response.json();

        this.setState({
            evalScore: currentEval,
            fetchInProgress: false
        });
    }

    onChangePgn = e => {
        this.setState({ currPgn: e.target.value });
    }

    onChangeTransitionTime = e => {
        this.setState({ transitionTime: e.target.value });
    }

    handleImportPgn = function () {
        if (this.state.gamePlayInProgress) {
            return;
        }
        this.playGame(this.state.currPgn);
    }

    endGame = function () {
        this.setState({ gamePlayInProgress: false });
        for (var i = 0; i < this.state.currTimeouts.length; i++) {
            clearTimeout(this.state.currTimeouts[i]);
        }
    }

    handleImportFile = function () {
        let file = document.getElementById("fileToLoad").files[0];
        const reader = new FileReader();
        reader.onload = event => this.finishImportFile(event.target.result);
        reader.readAsText(file);
    }

    handleTalRequest(event, ) {
        event.preventDefault();

        //This will grab a random PGN from our database
        // var urlToGetGame = "api/GetRandomPgn?PlayerName=Tal";

        //This will grab a random PGN from a folder of files on the server
        var urlToGetGame = "api/GetGameFromFolder?lastName=Tal";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleCapablancaRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Capablanca";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleFischerRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Fischer";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleNezhmetdinovRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Nezhmetdinov";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleCarlsenRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Carlsen";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleGiriRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Giri";

        this.fetchGameFromFolder(urlToGetGame);
    }

    handleNimzoRequest(event) {
        event.preventDefault();
        var urlToGetGame = "api/GetGameFromFolder?lastName=Nimzowitsch";

        this.fetchGameFromFolder(urlToGetGame);
    }

    fetchGameFromFolder = function (url) {
        fetch(url, { method: 'GET' })
            .then(response => response.json())
            .then(data => this.startGameAfterServerCall(data))
            .catch((error) => { console.log("ERROR: " + error); });
    }

    startGameAfterServerCall(pgn) {
        this.setState({ pgnObj: pgn })
        this.finishImportFile(pgn.pgnMoves);
    }

    finishImportFile = function (pgn) {
        if (this.state.gamePlayInProgress) {
            alert("Game is already in progress...");
            return;
        }
        this.playGame(pgn);
    }

    flipBoard = function () {
        this.setState({ boardOrientation: this.state.boardOrientation === 'black' ? 'white' : 'black' });
    }

    render() {

        return (
            <div>
                <Button className="btn btn-link collapse-btn" id="toggler3">Game Viewer</Button>
                <Card>
                    <CardBody className="chessboard-center">
                        <Container>
                            <table>
                                <tr>
                                    <td width="560px">
                                        <Chessboard position={this.state.fen} draggable={false} orientation={this.state.boardOrientation} />

                                        {this.state.pgnObj.whitePlayer &&
                                            <div>
                                                <br />
                                                <span>{this.state.pgnObj.whitePlayer} (W) vs. {this.state.pgnObj.blackPlayer} (B)</span>
                                                {this.state.pgnObj.eventName &&
                                                    <span className="brown-text"> @ {this.state.pgnObj.eventName}</span>
                                                }
                                                {this.state.pgnObj.eventYear &&
                                                    <span className="brown-text"> ({this.state.pgnObj.eventYear})</span>
                                                }
                                                <br />
                                                {this.state.pgnObj.resultDisplay &&
                                                    <span className="font-italic purple-text"> ... {this.state.pgnObj.resultDisplay}</span>
                                                }
                                            </div>
                                        }
                                    </td>
                                    <td width="30px" className="td-align-top">
                                        <EvalGauge evalScore={this.state.evalScore} />
                                    </td>
                                    <td className="td-align-top">
                                        <div>
                                            <Label className="form-pad-right font-weight-bold" for="lblEngineEval">Evaluation:</Label>
                                            <Label id="lblEngineEval" className="h4 text-primary">{this.state.evalScore}</Label>
                                        </div>
                                        <Input className="form-pad-top" type="textarea" rows="5" name="pgn" value={this.state.currPgn} onChange={this.onChangePgn} />
                                        <Button className="form-padding" color="info" onClick={this.handleImportPgn}>Import PGN</Button>
                                        <Button className="form-padding" color="info" onClick={this.flipBoard}>Flip Board</Button>
                                        <br />
                                        <h5>Transition Time (seconds):   </h5>
                                        <Input className="form-padding" type="text" value={this.state.transitionTime} onChange={this.onChangeTransitionTime} />
                                        <Button className="form-padding" color="warning" onClick={this.endGame}>Stop Current Game</Button>
                                        <br />
                                        <div>
                                            <Input className="form-padding" type="file" id="fileToLoad" />
                                            <Button className="form-padding" onClick={this.handleImportFile}>Import</Button>
                                        </div>
                                        <div>
                                            <h5>Select a Random Game from a Game Collection</h5>

                                            <div class="dropdown">
                                                <button class="dropbtn">Dropdown</button>
                                                <div class="dropdown-content">
                                                    <a href="" onClick={this.handleTalRequest}>Mikhail Tal's Amazing Sacrifices</a>
                                                    <a href="" onClick={this.handleCapablancaRequest}>Jose Capablanca's Finest Endgames</a>
                                                    <a href="" onClick={this.handleFischerRequest}>Robert Fischer's Dominating Victories</a>
                                                    <a href="" onClick={this.handleNezhmetdinovRequest}>Rashid Nezhmetdinov's Insane Attacks</a>
                                                    <a href="" onClick={this.handleCarlsenRequest}>Magnus Carlsen's Modern Brilliancies</a>
                                                    <a href="" onClick={this.handleGiriRequest}>Anish Giri's Best Draws</a>
                                                    <a href="" onClick={this.handleNimzoRequest}>Aron Nimzowitsch's Positional Brilliancies</a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </Container>
                    </CardBody>
                </Card>

            </div >
        );
    }
}

