import React, { Component } from 'react';
import Chessboard from 'chessboardjsx';
import Chess from "chess.js";
import { Form, Input, FormGroup, Button, Card, CardBody, Container, Label, Table } from 'reactstrap';
import EvalGauge from '../components/EvalGauge';

export class Home extends Component {
    static displayName = Home.name;
    state = {
        fen: '',
        fenInTextbox: '',
        squareSelected: '',
        squareClicked: '',
        squareStyles: {},
        defaultStyles: {},
        square: '',
        pieceSquare: '',
        history: null,
        pgn: '',
        test: '',
        analysisObj: null,
        depth: 15,
        fetchInProgress: false,
        currentTurn: 'White',
        maxAnalysisInProgress: false,
        moveDisplays: [],
        pressedKeys: [],
        moveDisplaysO: {},
        arrowSquares: [],
        progress: 5,
        retryMistakesBtnStyle: {
            display: 'none'
        },
        whiteMistakes: [],
        blackMistakes: [],
        evalScore: 0
    };

    constructor(props) {
        super(props);
        this.handleSubmitFen = this.handleSubmitFen.bind(this);
        this.handleSubmitPgn = this.handleSubmitPgn.bind(this);
        this.onChangeFen = this.onChangeFen.bind(this);
        this.onChangePgn = this.onChangePgn.bind(this);
        this.onChangeDepth = this.onChangeDepth.bind(this);
        this.goMaxDepth = this.goMaxDepth.bind(this);
        this.getDeepAnalysis = this.getDeepAnalysis.bind(this);
        this.onSquareRightClick = this.onSquareRightClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onSquareClick = this.onSquareClick.bind(this);
        this.drawArrow = this.drawArrow.bind(this);
    };


    componentDidMount() {
        this.game = new Chess();
        this.setState({
            fen: this.game.fen(),
            fenInTextbox: this.game.fen(),
            defaultStyles: this.state.squareStyles,
        });
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
        this.drawArrow('e2e4');

        //setInterval(() => {
        //    this.setState(prevState => ({
        //        progress:
        //            (prevState.progress + Math.floor(Math.random() * 20) + 1) % 100
        //    }));
        //}, 1000);
    }

    onKeyDown = function (e) {
        let pressedKeysD = this.state.pressedKeys;
        pressedKeysD[e.keyCode] = true;
        this.setState({ pressedKeys: pressedKeysD });
    }

    onKeyUp = function (e) {
        let pressedKeysD = this.state.pressedKeys;
        pressedKeysD[e.keyCode] = false;
        this.setState({ pressedKeys: pressedKeysD });

    }

    onDrop = ({ sourceSquare, targetSquare }) => {
        console.log("Source: " + sourceSquare);
        console.log("Target: " + targetSquare);
        if (this.state.fetchInProgress) { return; }

        let move = this.game.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q",
        });
        if (move === null) { return; }
        this.setState(({ history, pieceSquare }) => ({
            fen: this.game.fen(),
            pgn: this.game.pgn(),
            fenInTextbox: this.game.fen(),
            history: this.game.history({ verbose: true })
        }));
        this.game.move(move);
        this.getAnalysisFromFen(this.game.fen(), {
            finish: true,
            sourceSquare: sourceSquare,
            targetSquare: targetSquare
        });
        this.setCurrentTurn();
    };

    onDropFinish = function (sourceSquare, targetSquare) {
        let moveDisplaysD = this.state.moveDisplays;
        if (this.game.turn() === 'b') {
            moveDisplaysD.push({
                ply: (this.state.history.length - 1) / 2,
                whiteMove: sourceSquare + targetSquare,
                whiteEval: this.state.analysisObj.engineEvalDisplay,
                whiteAnnotation: "",
                whiteColor: "black",
                whiteFen: this.game.fen(),
            });
            this.setState({ moveDisplays: moveDisplaysD });
        } else {

            moveDisplaysD[this.state.history.length / 2 - 1].blackMove = sourceSquare + targetSquare;
            moveDisplaysD[this.state.history.length / 2 - 1].blackEval = this.state.analysisObj.engineEvalDisplay;
            moveDisplaysD[this.state.history.length / 2 - 1].blackAnnotation = "";
            moveDisplaysD[this.state.history.length / 2 - 1].blackColor = "black";
            moveDisplaysD[this.state.history.length / 2 - 1].blackFen = this.game.fen();

            this.setState({ moveDisplays: moveDisplaysD });
        }
    }

    setCurrentTurn() {
        if (this.game.turn() === 'w') {
            this.setState({ currentTurn: 'White' });
        } else {
            this.setState({ currentTurn: 'Black' });
        }
    }

    onSquareRightClick = square => {
        console.log(square);
        let squareStylesD = this.state.squareStyles;
        if (squareStylesD[square] !== undefined) {
            if (squareStylesD[square].backgroundColor !== undefined) {
                squareStylesD[square].backgroundColor = undefined;
            } else if (this.state.pressedKeys[17]) {
                squareStylesD[square].backgroundColor = "yellow";
            } else if (this.state.pressedKeys[16] && this.state.pressedKeys[18]) {
                squareStylesD[square].backgroundColor = "blue";
            } else if (this.state.pressedKeys[16]) {
                squareStylesD[square].backgroundColor = "#21C800";

            } else if (this.state.pressedKeys[18]) {
                squareStylesD[square].backgroundColor = "purple";

            } else {
                squareStylesD[square].backgroundColor = "red";

            }
        } else {
            squareStylesD[square] = {};
            if (this.state.pressedKeys[17]) {
                squareStylesD[square].backgroundColor = "yellow";
            } else if (this.state.pressedKeys[16] && this.state.pressedKeys[18]) {
                squareStylesD[square].backgroundColor = "blue";
            } else if (this.state.pressedKeys[16]) {
                squareStylesD[square].backgroundColor = "#21C800";

            } else if (this.state.pressedKeys[18]) {
                squareStylesD[square].backgroundColor = "purple";

            } else {
                squareStylesD[square].backgroundColor = "red";

            }
        }
        this.setState({ squareStyles: squareStylesD });
    }

    charToNum(char) {
        if (char === 'a') { return 1; }
        else if (char === 'b') { return 2; }
        else if (char === 'c') { return 3; }
        else if (char === 'd') { return 4; }
        else if (char === 'e') { return 5; }
        else if (char === 'f') { return 6; }
        else if (char === 'g') { return 7; }
        else if (char === 'h') { return 8; }
    }

    numToChar(num) {
        if (num === 1) { return 'a'; }
        else if (num === 2) { return 'b'; }
        else if (num === 3) { return 'c'; }
        else if (num === 4) { return 'd'; }
        else if (num === 5) { return 'e'; }
        else if (num === 6) { return 'f'; }
        else if (num === 7) { return 'g'; }
        else if (num === 8) { return 'h'; }
    }


    drawArrow(move) {
        let chars = [move.substring(0, 1), move.substring(1, 2), move.substring(2, 3), move.substring(3, 4)];
        let move1 = move.substring(0, 2);
        let move2 = move.substring(2, 4);
        chars[0] = this.charToNum(chars[0]);
        chars[2] = this.charToNum(chars[2]);



        //ex: "url('garrow-0.png')"
        let tmpStyles = {};
        tmpStyles[move1] = {
            backgroundImage: "",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
        };
        tmpStyles[move2] = {
            backgroundImage: "",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom"
        };

        var i;

        //up, down, left, right
        if (chars[0] === chars[2]) {
            if (chars[3] > chars[1]) {
                for (i = chars[1]; i < chars[3]; i++) {
                    let currSquare = this.numToChar(chars[0]) + i;
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowr-0.png')";
                }
                tmpStyles[move1].backgroundImage = "url('garrow-0.png')";
                tmpStyles[move2].backgroundImage = "url('garrowe-0.png')";
            } else {
                for (i = chars[1]; i > chars[3]; i--) {
                    let currSquare = this.numToChar(chars[0]) + i;
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowr-0.png')";
                }
                tmpStyles[move1].backgroundImage = "url('garrow-180.png')";
                tmpStyles[move2].backgroundImage = "url('garrowe-180.png')";
            }
        }
        else if (chars[1] === chars[3]) {
            if (chars[2] > chars[0]) {
                for (i = chars[0]; i < chars[2]; i++) {
                    let currSquare = this.numToChar(i) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowr-90.png')";
                }
                tmpStyles[move1].backgroundImage = "url('garrow-90.png')";
                tmpStyles[move2].backgroundImage = "url('garrowe-90.png')";
            } else {
                for (i = chars[0]; i > chars[2]; i--) {
                    let currSquare = this.numToChar(i) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowr-90.png')";
                }
                tmpStyles[move1].backgroundImage = "url('garrow-270.png')";
                tmpStyles[move2].backgroundImage = "url('garrowe-270.png')";
            }
        }
        //diagonals
        else if (Math.abs(chars[3] - chars[1]) === Math.abs(chars[2] - chars[0])) {
            if (chars[3] > chars[1]) {
                if (chars[2] > chars[0]) {
                    for (i = 0; i < chars[3] - chars[1]; i++) {
                        let currSquare = this.numToChar(chars[0] + i) + (+ chars[1] + i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowb-45.png')";
                        currSquare = this.numToChar(chars[0] + i + 1) + (+ chars[1] + i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbr-45.png')";
                        currSquare = this.numToChar(chars[0] + i) + (+ chars[1] + i + 1);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbl-45.png')";
                    }
                    tmpStyles[move1].backgroundImage = "url('garrow-45.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-45.png')";
                } else {
                    for (i = 0; i < chars[3] - chars[1]; i++) {
                        let currSquare = this.numToChar(chars[0] - i) + (+ chars[1] + i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowb-135.png')";
                        currSquare = this.numToChar(chars[0] - i - 1) + (+ chars[1] + i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbl-135.png')";
                        currSquare = this.numToChar(chars[0] - i) + (+ chars[1] + i + 1);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbr-135.png')";
                    }
                    tmpStyles[move1].backgroundImage = "url('garrow-315.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-315.png')";
                }
            } else {
                if (chars[2] > chars[0]) {
                    for (i = 0; i < chars[1] - chars[3]; i++) {
                        let currSquare = this.numToChar(chars[0] + i) + (+ chars[1] - i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowb-135.png')";
                        currSquare = this.numToChar(chars[0] + i + 1) + (+ chars[1] - i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbr-135.png')";
                        currSquare = this.numToChar(chars[0] + i) + (+ chars[1] + i - 1);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbl-135.png')";
                    }
                    tmpStyles[move1].backgroundImage = "url('garrow-135.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-135.png')";
                } else {
                    for (i = 0; i < chars[1] - chars[3]; i++) {
                        let currSquare = this.numToChar(chars[0] - i) + (+ chars[1] - i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowb-45.png')";
                        currSquare = this.numToChar(chars[0] - i - 1) + (+ chars[1] - i);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbl-45.png')";
                        currSquare = this.numToChar(chars[0] - i) + (+ chars[1] - i - 1);
                        if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                        tmpStyles[currSquare].backgroundImage = "url('garrowbr-45.png')";
                    }
                    tmpStyles[move1].backgroundImage = "url('garrow-225.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-225.png')";
                }
            }
        }
        //knight moves
        else {
            if (chars[3] - chars[1] === 2) {
                if (chars[2] > chars[0]) {
                    let currSquare = this.numToChar(chars[0]) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-30.png')";
                    currSquare = this.numToChar(chars[0] + 1) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-30.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-30.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-30.png')";
                } else {
                    let currSquare = this.numToChar(chars[0]) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-150.png')";
                    currSquare = this.numToChar(chars[0] - 1) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-150.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-330.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-330.png')";
                }
            } else if (chars[3] - chars[1] === -2) {
                if (chars[2] > chars[0]) {
                    let currSquare = this.numToChar(chars[0]) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-150.png')";
                    currSquare = this.numToChar(chars[0] + 1) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-150.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-150.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-150.png')";
                } else {
                    let currSquare = this.numToChar(chars[0]) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-30.png')";
                    currSquare = this.numToChar(chars[0] - 1) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-30.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-210.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-210.png')";
                }
            } else if (chars[2] - chars[0] === 2) {
                if (chars[3] > chars[1]) {
                    let currSquare = this.numToChar(chars[0] + 1) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-60.png')";
                    currSquare = this.numToChar(chars[0] + 1) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-60.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-60.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-60.png')";
                } else {
                    let currSquare = this.numToChar(chars[0] + 1) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-120.png')";
                    currSquare = this.numToChar(chars[0] + 1) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-120.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-120.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-120.png')";
                }
            } else {
                if (chars[3] > chars[1]) {
                    let currSquare = this.numToChar(chars[0] - 1) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-120.png')";
                    currSquare = this.numToChar(chars[0] - 1) + (+ chars[1] + 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-120.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-300.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-300.png')";
                } else {
                    let currSquare = this.numToChar(chars[0] - 1) + chars[1];
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkl-60.png')";
                    currSquare = this.numToChar(chars[0] - 1) + (+ chars[1] - 1);
                    if (tmpStyles[currSquare] === undefined) { tmpStyles[currSquare] = {}; }
                    tmpStyles[currSquare].backgroundImage = "url('garrowkr-60.png')";
                    tmpStyles[move1].backgroundImage = "url('garrow-240.png')";
                    tmpStyles[move2].backgroundImage = "url('garrowe-240.png')";
                }
            }
        }

        var arrowSquaresD = this.state.arrowSquares;
        arrowSquaresD.push(move1);
        arrowSquaresD.push(move2);
        this.setState({ arrowSquares: arrowSquaresD });
        this.setState({ squareStyles: tmpStyles });
    }

    onSquareClick = square => {
        let testMoves = this.game.moves({ verbose: true });
        var finalMoveFrom;
        var finalMoveTo
        for (var i = 0; i < testMoves.length; i++) {
            if (testMoves[i].to === square) {
                finalMoveFrom = testMoves[i].from;
                finalMoveTo = testMoves[i].to;
            }
        }
        //onDrop begin
        let move = this.game.move({
            from: finalMoveFrom,
            to: finalMoveTo,
            promotion: "q",
        });
        if (move === null) { return; }
        this.setState(({ history, pieceSquare }) => ({
            fen: this.game.fen(),
            pgn: this.game.pgn(),
            fenInTextbox: this.game.fen(),
            history: this.game.history({ verbose: true })
        }));
        this.game.move(move);
        this.getAnalysisFromFen(this.game.fen(), {
            finish: true,
            sourceSquare: finalMoveFrom,
            targetSquare: finalMoveTo
        });
        this.setCurrentTurn();
        //onDrop end
    }

    onChangeFen = e => {
        this.setState({ fenInTextbox: e.target.value });
    }

    onChangePgn = e => {
        this.setState({ pgn: e.target.value });
    }

    onChangeDepth = e => {
        this.setState({ maxAnalysisInProgress: false });
        this.setState({ depth: e.target.value });
    }

    handleSubmitFen(event) {
        event.preventDefault();
        this.loadFen(this.state.fenInTextbox);
    }

    loadFen(fen) {
        this.game.load(fen);
        this.setState({ fen: fen })
        this.setCurrentTurn();
        this.getAnalysisFromFen(fen);
        this.setState({ moveDisplays: [] });
        this.setState({
            squareStyles: {}
        });
    }

    handleSubmitPgn(event) {
        event.preventDefault();
        this.setState({
            squareStyles: {}
        });
        this.loadPgnIntoChessboard();
    }

    loadPgnIntoChessboard() {
        this.game.load_pgn(this.state.pgn);
        let newFen = this.game.fen();
        this.setState({
            fen: newFen,
            fenInTextbox: newFen
        });
        this.setCurrentTurn();
        this.getAnalysisFromPgn();
    }


    goMaxDepth(event) {
        event.preventDefault();
        if (this.state.maxAnalysisInProgress) {
            this.setState({ maxAnalysisInProgress: false });
        } else {
            this.setState({ maxAnalysisInProgress: true });
            this.getAnalysisFromFen(this.state.fen);
        }
    }

    async getDeepAnalysis() {
        if (this.state.pgn === '') {
            alert("You must enter a PGN first");
            return;
        }
        this.loadPgnIntoChessboard();
        this.setState({
            squareStyles: {}
        });
        this.setState({
            retryMistakesBtnStyle: {
                display: 'none'
            },
            whiteMistakes: [],
            blackMistakes: [],
            moveDisplays: []
        })
        this.setState({ maxAnalysisInProgress: false });
        this.setState({ fetchInProgress: true });
        this.setState({ movesDisplays: [] });

        let history = this.game.history();
        let previousEval = 0.3;
        let currentEval = 0.3;
        let moves = [];
        let gamePos = 0;
        let mistakesD;
        this.game.reset();
        for (var i = 0; i < history.length; i++) {
            this.game.move(history[i]);
            var url = 'api/GetEvalFromFen?id=' + this.game.fen() + '&depth=' + this.state.depth + '&turn=' + this.game.turn();
            var response = await fetch(url);
            currentEval = await response.json();

            if (this.game.turn() === 'b') {
                moves.push({
                    ply: gamePos,
                    whiteMove: history[i],
                    whiteEval: currentEval,
                    whiteAnnotation: "",
                    whiteColor: "black",
                    whiteFen: this.game.fen(),
                    blackMove: "",
                    blackEval: "",
                    blackAnnotation: "",
                    blackColor: "black",
                    blackFen: "",
                });
                if ((currentEval > 4 && previousEval > 4) || (currentEval < -4 && previousEval < -4)) { }
                else if (currentEval + 3 < previousEval) {
                    moves[gamePos].whiteAnnotation = "??";
                    moves[gamePos].whiteColor = "red";
                    mistakesD = this.state.whiteMistakes;
                    mistakesD.push(moves[gamePos - 1]);
                    this.setState({ whiteMistakes: mistakesD });
                } else if (currentEval + 1.8 < previousEval) {
                    moves[gamePos].whiteAnnotation = "?";
                    moves[gamePos].whiteColor = "orange";
                    mistakesD = this.state.whiteMistakes;
                    mistakesD.push(moves[gamePos - 1]);
                    this.setState({ whiteMistakes: mistakesD });
                } else if (currentEval + 0.8 < previousEval) {
                    moves[gamePos].whiteAnnotation = "?!";
                    moves[gamePos].whiteColor = "#edd900";
                }
            }
            else if (this.game.turn() === 'w') {
                console.log("Position: " + gamePos);
                moves[gamePos].blackMove = history[i];
                moves[gamePos].blackEval = currentEval;
                moves[gamePos].blackFen = this.game.fen();
                if ((currentEval > 4 && previousEval > 4) || (currentEval < -4 && previousEval < -4)) { }
                else if (currentEval - 3 > previousEval) {
                    moves[gamePos].blackAnnotation = "??";
                    moves[gamePos].blackColor = "red";
                    mistakesD = this.state.blackMistakes;
                    mistakesD.push(moves[gamePos]);
                    this.setState({ blackMistakes: mistakesD });
                } else if (currentEval - 2 > previousEval) {
                    moves[gamePos].blackAnnotation = "?";
                    moves[gamePos].blackColor = "orange";
                    mistakesD = this.state.blackMistakes;
                    mistakesD.push(moves[gamePos]);
                    this.setState({ blackMistakes: mistakesD });
                } else if (currentEval - 0.8 > previousEval) {
                    moves[gamePos].blackAnnotation = "?!";
                    moves[gamePos].blackColor = "#edd900";
                }

                gamePos += 1;
            }
            previousEval = currentEval;
        }

        this.setState({
            moveDisplays: moves,
            fetchInProgress: false,
            retryMistakesBtnStyle: {
                display: 'block'
            }
        });
    }

    async getAnalysisFromPgn() {
        this.setState({ fetchInProgress: true });

        if (this.state.depth === '') {
            this.setState({ depth: 10 });
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                pgn: this.state.pgn,
                currentFen: this.game.fen(),
                currentTurn: this.game.turn(),
                moves: this.game.history(),
                depth: this.state.depth
            })
        };

        fetch('api/GetAnalysisFromPgn', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({
                analysisObj: data,
                evalScore: data.engineEvaluation,
                fetchInProgress: false
            }))
    }


    async getAnalysisFromFen(currentFen, onDropData) {
        this.setState({ fetchInProgress: true });
        if (this.state.depth === '') {
            this.setState({ depth: 10 });
        }

        const url = 'api/GetAnalysisFromFen?id=' + currentFen + '&depth=' + this.state.depth + '&turn=' + this.game.turn();
        //console.log(url);
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            analysisObj: data,
            evalScore: data.engineEvaluation,
            fetchInProgress: false
        });
        this.drawArrow(data.nextBestMove);

        if (this.state.maxAnalysisInProgress) {

            if (this.state.depth > 40) {
                this.setState({ maxAnalysisInProgress: false })
            } else {
                this.setState({ depth: parseInt(this.state.depth, 10) + 1 });
                this.getAnalysisFromFen(currentFen);
            }
        }
        if (onDropData !== undefined) {
            if (onDropData.finish) {
                this.onDropFinish(onDropData.sourceSquare, onDropData.targetSquare);
            }
        }
    }

    render() {

        return (
            <div>
                <Button className="btn btn-link collapse-btn" id="toggler1">Chessboard</Button>
                <Card>
                    <CardBody className="chessboard-center">
                        <Container>
                            <table>
                                <tr>
                                    <td width="560px">
                                        <Chessboard position={this.state.fen} draggable={true} onDrop={this.onDrop} onSquareRightClick={this.onSquareRightClick} squareStyles={this.state.squareStyles} onSquareClick={this.onSquareClick} />
                                    </td>
                                    <td width="30px" className="td-align-top">
                                        <EvalGauge evalScore={this.state.evalScore} />
                                    </td>
                                    <td className="td-align-top">
                                        <Form>
                                            <FormGroup>
                                                <Label className="form-pad-right font-weight-bold" for="lblEngineEval">Evaluation:</Label>
                                                {
                                                    (this.state.fetchInProgress && !this.state.maxAnalysisInProgress)
                                                        ? <span>loading...</span>
                                                        : <Label id="lblEngineEval" className="h4 text-primary">{this.state.analysisObj === null ? '+0.3' : this.state.analysisObj.engineEvalDisplay}</Label>
                                                }

                                                <br /><Label className="form-pad-right font-weight-bold" for="lblBestMove">Next Best Move:</Label>
                                                {
                                                    (this.state.fetchInProgress && !this.state.maxAnalysisInProgress)
                                                        ? <span>loading...</span>
                                                        : <span>
                                                            <Label id="lblBestMove">{this.state.analysisObj === null ? 'e2e4' : this.state.analysisObj.nextBestMove}</Label>
                                                            <Label className="form-pad-right font-weight-bold" for="lblPonderMove">&nbsp;&nbsp;(ponder: </Label>
                                                            <Label id="lblPonderMove">{this.state.analysisObj === null ? 'e7e5' : this.state.analysisObj.nextPonderMove}</Label>
                                                            <Label className="form-pad-right font-weight-bold" for="lblPonderMove">)</Label>
                                                        </span>
                                                }

                                                <br /><Label className="form-pad-right font-weight-bold" for="lblCurrentTurn">Current Turn:</Label>
                                                <Label id="lblCurrentTime">{this.state.currentTurn}</Label>

                                                <br /><Label className="form-pad-right font-weight-bold" for="txtDepth">Engine Depth:</Label>
                                                <Input id="txtDepth" type="text" style={{ display: 'inline', width: '50px', height: '30px' }} value={this.state.depth} onChange={this.onChangeDepth}></Input>
                                                <Button id="maxDepthButton" className="inline-btn" onClick={this.goMaxDepth}>Max Depth</Button>
                                                <Button color="success" id="getDeepAnalysisBtn" className="inline-btn" onClick={this.getDeepAnalysis}>Deep Analysis</Button>

                                                {/* Table displaying all of the moves of the game/PGN */}
                                                <div id="deepAnalysisResult">
                                                    <Table striped hover>
                                                        <tbody>
                                                            {this.state.moveDisplays.map(chessMove => (
                                                                <tr key={chessMove.ply} >
                                                                    <td className="font-weight-bold">{chessMove.ply + 1}.</td>
                                                                    <td>{chessMove.whiteMove}
                                                                        <span className="font-weight-bold" style={{ color: chessMove.whiteColor }}>{chessMove.whiteAnnotation}</span>
                                                                        {chessMove.whiteEval &&
                                                                            <span> ({chessMove.whiteEval})</span>
                                                                        }
                                                                    </td>
                                                                    <td>{chessMove.blackMove}
                                                                        <span className="font-weight-bold" style={{ color: chessMove.blackColor }}>{chessMove.blackAnnotation}</span>
                                                                        {chessMove.blackEval &&
                                                                            <span> ({chessMove.blackEval})</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <h5 style={this.state.retryMistakesBtnStyle}>Retry White Mistakes</h5>
                                                    {this.state.whiteMistakes.map(wMistake => (
                                                        <Button color="light" style={this.state.retryMistakesBtnStyle} onClick={() => window.open("https://localhost:44357/loadpuzzle/" + wMistake.blackFen)}>Retry</Button>
                                                    ))}
                                                    <h5 style={this.state.retryMistakesBtnStyle}>Retry Black Mistakes</h5>
                                                    {this.state.blackMistakes.map(bMistake => (
                                                        <Button color="dark" style={this.state.retryMistakesBtnStyle} onClick={() => window.open("https://localhost:44357/loadpuzzle/" + bMistake.whiteFen)}>Retry</Button>
                                                    ))}

                                                </div>

                                            </FormGroup>
                                        </Form>
                                    </td>
                                </tr>
                            </table>
                        </Container>
                    </CardBody>
                </Card>

                <Button className="btn-link collapse-btn form-padding-top" id="toggler2">PGN & FEN</Button>
                <Card>
                    <CardBody>
                        <Form onSubmit={this.handleSubmitFen}>
                            <FormGroup>
                                <Input className="form-pad-top" type="textarea" rows="1" name="fen" value={this.state.fenInTextbox} onChange={this.onChangeFen} />
                                <Button className="form-padding" color="primary" type="submit">Import FEN</Button>
                            </FormGroup>
                        </Form>
                        <Form onSubmit={this.handleSubmitPgn}>
                            <FormGroup>
                                <Input className="form-pad-top" type="textarea" rows="5" name="pgn" value={this.state.pgn} onChange={this.onChangePgn} />
                                <Button className="form-padding" color="primary" type="submit">Import PGN</Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div >
        );
    }
}

